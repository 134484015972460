<template>
  <div>
    <h3>
      {{ $t("allTaxReturn") }} <span class="text-muted">{{ count }}</span>
    </h3>
    <p class="text-muted">{{ $t("customersDescription") }}</p>
    <div class="text-right">
      <export-excel
        v-if="checkedList.length > 0"
        :fields="json_fields"
        :name="`${checkedList.length}-${$t(
          'allTaxReturn'
        )}-${new Date().toLocaleDateString('tr')}.${getOS.excell}`"
        :type="`${getOS.excell}`"
        :data="checkedList"
      >
        <button class="blue-button bg-success btn-xs font-size-15">
          <font-awesome-icon :icon="faFileExcel"></font-awesome-icon>
          {{ $t("exportToExcel") }} ({{ checkedList.length }})
        </button>
      </export-excel>
    </div>
    <div class="row justify-content-end my-2 account-settings">
      <div class="col-8 col-md-6 col-lg-4 col-xl-3">
        <div>
          <span>{{ $t("paymentOption") }}</span>
        </div>
        <b-form-select
          v-model="paymentOption"
          @change="changePaymentOption"
          class="lang-select-box"
        >
          <b-form-select-option value="">{{
            $t("allStatus")
          }}</b-form-select-option>
          <b-form-select-option
            :value="option.value"
            v-for="(option, i) in [
              { value: paymentOptions.PAYPAL, text: 'Paypal' },
              { value: paymentOptions.STRIPE, text: 'Stripe' },
            ]"
            :key="i"
            >{{ option.text }}</b-form-select-option
          >
        </b-form-select>
      </div>
      <div class="col-8 col-md-6 col-lg-4 col-xl-3">
        <div>
          <span>{{ $t("filterYear") }}</span>
        </div>
        <b-form-select
          v-model="taxYear"
          @change="changeTaxYear"
          class="lang-select-box"
        >
          <b-form-select-option value="">{{
            $t("allStatus")
          }}</b-form-select-option>
          <b-form-select-option
            :value="year.year"
            v-for="(year, i) in years"
            :key="i"
            >{{ $t(year.year) }}</b-form-select-option
          >
        </b-form-select>
      </div>
      <div class="col-8 col-md-6 col-lg-4 col-xl-3">
        <div>
          <span>{{ $t("filterByStatus") }}</span>
        </div>
        <b-form-select
          v-model="status"
          @change="SET_STATUS_FILTER()"
          class="lang-select-box"
        >
          <b-form-select-option
            :value="stat.value"
            v-for="(stat, i) in statusList"
            :key="i"
            >{{ $t(stat.text) }}</b-form-select-option
          >
        </b-form-select>
      </div>
      <div class="col-8 col-md-6 col-lg-4 col-xl-3">
        <div>
          <span>Admin</span>
        </div>
        <v-select
          :options="admins"
          label="item_data"
          @input="filterAdminList()"
          v-model="filterAdmin"
          class="lang-select-box"
          @search="findAdmin"
          :filterable="false"
          :placeholder="$t('callAdmin')"
        >
          <template #option="option">
            <span
              ><font-awesome-icon :icon="faCircleUser" class="mr-2" />{{
                option.name
              }}
              {{ option.surname }}</span
            >
          </template>
          <template #no-options="{ search, searching, loading }">
            <!-- {{ findAdmin(search) }} -->
            {{ $t("noSearchAdmin") }} {{ search }} {{ $t("noSearchAdminTwo") }}
          </template>
        </v-select>
      </div>
    </div>
    <Alert :alert="alert" />
    <BaseTable
      i18n
      checkable
      :checkedKey="['id', 'taxYear']"
      :loadingAllData="isLoadingAllData"
      :loading="isLoading"
      :checkValue="checkedList"
      @change:checkedList="checkedList = $event"
      @change:selectAllWithoutPage="GET_ALL_CUSTOMER"
      :items="items"
      :count="count"
      :limit="this.limit"
      @change:search="SET_SEARCH"
      :page="page"
      @change:page="SET_PAGINATION"
      :searchPlaceholder="$t('searchCustomerPlaceholder')"
      :pages="pages"
      :headers="headers"
      noCaption
    >
      <template #fullname="{ item }">
        <strong>
          <font-awesome-icon :icon="faUser" />

          {{ item.name }} {{ item.surname }}
        </strong>
        <div>
          <small class="text-muted">
            <font-awesome-icon :icon="faCalendar" />

            {{ setDate(item.createdDate) }}</small
          >

          <small class="text-muted">
            -
            <a class="d-inline-block" :href="`mailto:${item.email}`">
              <font-awesome-icon :icon="faEnvelope" />

              {{ item.email }}</a
            ></small
          >
          <div
            class="position-relative"
            @mouseover="showInfoIndex = item.id"
            @mouseleave="showInfoIndex = null"
          >
            <small class="text-muted">
              <font-awesome-icon :icon="faRegistered" />

              {{ setDate(item.registeredDate) }}</small
            >
            <small class="text-muted">
              - {{ $t(`gender.${item.gender}`) }}
            </small>
            <div class="document-message" v-if="showInfoIndex === item.id">
              {{ $t("registeredDateOfCustomer") }}
            </div>
          </div>
        </div>
      </template>
      <template #phoneNo="{ item }">
        <a :href="`tel:${item.phoneNo}`">
          <span class="badge badge-soft-dark px-1 w-100">
            <font-awesome-icon :icon="faPhone" />
            {{ item.phoneNo }}</span
          >
        </a>
      </template>
      <template #action="{ item }">
        <div class="mt-3 mt-md-0 d-flex justify-content-end align-items-center">
          <router-link
            :to="`/admin/kunden/${item.id}/${item.taxYear}`"
            class="mr-3"
          >
            <button type="button" class="blue-button btn-xs font-size-15">
              {{ $t("viewFiles") }}
              <font-awesome-icon :icon="faEye" />
            </button>
          </router-link>
          <font-awesome-icon
            :icon="faTrash"
            class="text-danger"
            @click="() => deleteUser(item.id)"
          />
        </div>
      </template>
      <template #status="{ item }">
        <div class="text-right text-md-left">
          <div class="position-relative">
            <small
              :class="`w-100 badge badge-soft-${userStatus(item.status).class}`"
              >{{ $t(userStatus(item.status).text) }}</small
            >
          </div>
        </div></template
      >
      <template #authorised="{ item }">
        <v-select
          :options="admins"
          label="item_data"
          @input="assignmentAdminAction(item.id, item.taxYear)"
          v-model="admin[item.id + item.taxYear]"
          class="find-admin"
          @search="findAdmin"
          :filterable="false"
          :placeholder="$t('selectAdmin')"
          :disabled="activeUser.role !== 'Manager'"
        >
          <template #selected-option="option">
            <span
              ><font-awesome-icon :icon="faCircleUser" class="mr-2" />
              {{ option.item_data }}</span
            >
          </template>
          <template #option="option">
            <span
              ><font-awesome-icon :icon="faCircleUser" class="mr-2" />{{
                option.name
              }}
              {{ option.surname }}</span
            >
          </template>
          <template #no-options="{ search, searching, loading }">
            <!-- {{ findAdmin(search) }} -->
            {{ $t("noSearchAdmin") }} {{ search }} {{ $t("noSearchAdminTwo") }}
          </template>
        </v-select>
      </template>
      <template #taxYear="{ item }">
        <div class="text-md-left">
          {{ item.taxYear }}
        </div>

        <img
          v-if="item.paymentServiceId"
          width="45"
          @mouseover="showPayInfoIndex = item.id"
          @mouseleave="showPayInfoIndex = null"
          :src="
            require(`@/assets/img/paymentOptions/${item.paymentServiceId}.png`)
          "
          alt=""
        />
        <div class="document-message" v-if="showPayInfoIndex === item.id">
          {{ $t("payerFullname") }}: {{ item.payerFullname }} <br />
          E-mail: {{ item.payerEmail }} <br />
          {{ $t("amount") }}: {{ item.amount }} {{ item.amountCurrency }}
        </div>
      </template>
    </BaseTable>
  </div>
</template>

<script>
import BaseTable from "../../base/Table/index.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import paymentOptions from "../../../enums/paymentOptions";
import {
  faEye,
  faPhone,
  faEnvelope,
  faCalendar,
  faUser,
  faCircleUser,
  faUserCheck,
  faRegistered,
  faFileExcel,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  adminDeleteUser,
  getUsers,
  getYears,
} from "../../../services/modules/User";
import { mapGetters } from "vuex";
import {
  assignmentAdminManager,
  filterAdminManager,
  getAdmins,
} from "../../../services/modules/Admin";
import Alert from "../../base/alert/Alert.vue";
import Swal from "sweetalert2";

const year = new Date().getFullYear() - 1;

export default {
  components: {
    BaseTable,
    FontAwesomeIcon,
    Alert,
  },
  data() {
    return {
      paymentOptions,
      faFileExcel,
      faRegistered,
      faEye,
      faPhone,
      faUser,
      faCalendar,
      faTrash,
      faEnvelope,
      faCircleUser,
      faUserCheck,
      search: "",
      status: -1,
      showInfoIndex: -1,
      showPayInfoIndex: -1,
      showAssignmentAdmin: false,
      paymentOption: "",
      checkedList: [],
      isLoadingAllData: false,
      json_fields: {
        "Kunden ID": "invalid",
        Kundennummer: "invalid",
        Abteilung: "invalid",
        Anrede: {
          field: "gender",
          callback: (value) => {
            return this.$t(`genderExcell.${value}`);
          },
        },
        "Persönlich/Vertraulich": "invalid",
        Titel: "invalid",
        Vorname: "name",
        Name: "surname",
        Geburtsdatum: {
          field: "birthDate",
          callback: (value) => {
            return new Date(value).toLocaleDateString("tr") || value;
          },
        },
        Firma: "invalid",
        "Straße/Hausnummer": "address.street",
        Postleitzahl: "address.postalCode",
        Ort: "address.city",
        Bundesland: "invalid",
        Land: {
          field: "default",
          callback: () => "Deutschland",
        },
        "Land (Code)": {
          field: "default",
          callback: () => "DE",
        },
        Postfach: "invalid",
        "Postfach-Postleitzahl": "invalid",
        "Postfach-Ort": "invalid",
        "Postfach-Bundesland": "invalid",
        "Postfach-Land": "invalid",
        "Postfach-Land (Code)": "invalid",
        "Telefon 1": "invalid",
        "Telefon 2": "invalid",
        Fax: "invalid",
        Mobiltelefon: "phoneNo",
        "E-Mail": "email",
        "Weitere E-Mails": "invalid",
        Internet: "invalid",
        "Info 1": "invalid",
        "Info 2": "invalid",
        "Steuer Nr.": "invalid",
        "Umsatzsteuer-ID Nr.": "invalid",
        Registergericht: "invalid",
        Registernummer: "invalid",
        Kontoinhaber: "invalid",
        Bankname: "invalid",
        Kontonummer: "invalid",
        Bankleitzahl: "invalid",
        BIC: "invalid",
        IBAN: "iban",
        "Zusatz 1": "invalid",
        "Zusatz 2": "invalid",
        Notiz: "invalid",
        Kundengruppe: "invalid",
        "Zusätzliche Kundengruppen": "invalid",
        "Titel-Lieferanschrift": "invalid",
        "Anrede-Lieferanschrift": "invalid",
        "Persönlich/Vertraulich-Lieferanschrift": "invalid",
        "Vorname-Lieferanschrift": "invalid",
        "Name-Lieferanschrift": "invalid",
        "Zusatz 1-Lieferanschrift": "invalid",
        "Zusatz 2-Lieferanschrift": "invalid",
        "Firma-Lieferanschrift": "invalid",
        "Straße/Hausnummer-Lieferanschrift": "invalid",
        "Postleitzahl-Lieferanschrift": "invalid",
        "Ort-Lieferanschrift": "invalid",
        "Bundesland-Lieferanschrift": "invalid",
        "Land-Lieferanschrift": "invalid",
        "Land-Lieferanschrift (Code)": "invalid",
        Preisgruppe: "invalid",
        "Kunde seit": "invalid",
        "SEPA-Lastschriftverfahren": "invalid",
        "Eingangsdatum des Mandates": "invalid",
        Mandatsreferenz: "invalid",
        "Skonto (%)": "invalid",
        "Skonto (Tage)": "invalid",
        "Rabatt (%/Betrag)": "invalid",
        "Zahlungsziel (Tage)": "invalid",
        Umsatzsteueroption: "invalid",
        "Umsatzsteueroption ID": "invalid",
        "Ansprechpartner ID": "invalid",
        "Dokumentformat beim Versenden": "invalid",
        "Referenz des Käufers": "invalid",
        "Lieferantennr. beim Kunden": "invalid",
        Archiviert: "invalid",
        "Leere Spalte": "invalid",
      },
      statusList: [
        { value: -1, text: "allStatus" },
        { value: 0, text: "waiting" },
        { value: 1, text: "reviewing" },
        { value: 4, text: "pendingConfirmation" },
        { value: 6, text: "paymentWaiting" },
        { value: 2, text: "completed" },
        { value: 5, text: "transmittedFa" },
        { value: 3, text: "canceled" },
      ],
      headers: [
        { title: "fullname", value: "fullname", col: 12, md: 4, lg: 2 },
        { title: "phone", value: "phoneNo", col: 3, md: 3, lg: 2 },
        { title: "status", value: "status", col: 3, md: 2, lg: 2 },
        {
          title: "authorised",
          value: "authorised",
          col: 3,
          md: 12,
          lg: 2,
        },
        { title: "taxYear", value: "taxYear", col: 3, md: 3, lg: 1 },
        { title: "", value: "action", col: 12, md: 12, lg: 2 },
      ],
      count: 0,
      page: 1,
      items: [],
      isLoading: true,
      activeUpdateStatus: -1,
      admins: [],
      limit: 10,
      admin: {},
      alert: {
        show: false,
        variant: "",
        message: "",
      },
      filterAdmin: null,
      searchTimeout: -1,
      years: [
        { year: year, hasFile: false },
        { year: year - 1, hasFile: false },
        { year: year - 2, hasFile: false },
        { year: year - 3, hasFile: false },
        { year: year - 4, hasFile: false },
      ],
      taxYear: "",
    };
  },
  computed: {
    ...mapGetters(["activeUser", "getOS"]),
    pages() {
      return Math.ceil(this.count / 10);
    },
  },
  methods: {
    deleteUser(id) {
      Swal.fire({
        title: `${this.$t("areYouSure")}`,
        text: `${this.$t("questionDeleteCustomer")}`,
        icon: "warning",
        iconColor: "#57c6ff",
        showCancelButton: true,
        confirmButtonColor: "#57c6ff",
        cancelButtonColor: "#f43958",
        confirmButtonText: `${this.$t("confirm")}`,
        cancelButtonText: `${this.$t("giveUp")}`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await adminDeleteUser(id);

          if (response.message === "OK") {
            Swal.fire({
              title: `${this.$t("success")}`,
              text: `${this.$t("deleteCustomer")}`,
              icon: "success",
              confirmButtonColor: "#57c6ff",
              confirmButtonText: `${this.$t("ok")}`,
            });
            const index = this.items.findIndex((item) => item.id === id);
            this.items.splice(index, 1);
          } else {
            Swal.fire({
              title: `${this.$t("failed")}`,
              text: `${this.$t("noDeleteCustomer")}`,
              icon: "error",
              confirmButtonText: `${this.$t("ok")}`,
            });
          }
        }
      });
    },
    userStatus(val) {
      return val === 0
        ? { class: "info", text: "waiting" }
        : val === 1
        ? { class: "warning", text: "reviewing" }
        : val === 2
        ? { class: "success", text: "completed" }
        : val === 4
        ? { class: "secondary", text: "pendingConfirmation" }
        : val === 5
        ? { class: "primary", text: "transmittedFa" }
        : val === 6
        ? { class: "pink", text: "paymentWaiting" }
        : { class: "danger", text: "canceled" };
    },
    async findAdmin(search, loading) {
      loading(true);
      if (this.searchTimeout !== -1) clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.adminsList(1, search, "", this.limit);
        loading(false);
      }, 1000);
    },
    async filterAdminList() {
      this.getUsersList();
    },
    async assignmentAdminAction(id, taxYear) {
      const response = await assignmentAdminManager(
        this.admin[id + taxYear].id,
        id,
        taxYear
      );

      if (response.message === "OK") {
        this.alert.show = true;
        this.alert.variant = "success";
        this.alert.message = "successAdminMessage";
        this.getUsersList();
      } else {
        this.alert.show = true;
        this.alert.variant = "danger";
        this.alert.message = "notSuccessAdminMessage";
      }

      setTimeout(() => {
        this.alert.show = false;
      }, 3000);
    },
    async adminsList(page, search, status, limit) {
      const response = await getAdmins(page, search, status, limit);

      if (response.message === "OK") {
        this.admins = response.data.items;
        this.admins.map((item) => {
          return (item.item_data = item.name + " " + item.surname);
        });
      }
    },
    async changeTaxYear() {
      this.page = 1;
      this.$router.push({ query: { ...this.$route.query, page: 1 } });
      this.getUsersList();
    },
    async changePaymentOption() {
      this.page = 1;
      this.$router.push({ query: { ...this.$route.query, page: 1 } });
      this.getUsersList();
    },
    SET_STATUS_FILTER() {
      this.page = 1;
      this.$router.push({
        query: { ...this.$route.query, page: 1, status: this.status },
      });
      this.getUsersList();
    },
    SET_SEARCH(search) {
      this.search = search;
      this.page = 1;
      this.$router.push({ query: { ...this.$route.query, search, page: 1 } });
      this.getUsersList(this.page, this.search, this.status);
    },
    SET_PAGINATION(page) {
      this.page = page;
      this.$router.push({ query: { ...this.$route.query, page } });
      this.getUsersList(this.page, this.search, this.status);
    },
    async GET_ALL_CUSTOMER(status) {
      if (status) {
        this.isLoadingAllData = true;
        const response = await getUsers(
          1,
          this.search,
          this.status,
          this.filterAdmin,
          this.taxYear,
          "",
          this.count
        );
        if (response.message === "OK") {
          this.checkedList = response.data.items;
        }
        this.isLoadingAllData = false;
      }
    },
    async getUsersList() {
      this.isLoading = true;
      const response = await getUsers(
        this.page,
        this.search,
        this.status,
        this.filterAdmin,
        this.taxYear,
        "",
        10,
        this.paymentOption
      );
      if (response.message === "OK") {
        this.items = response.data.items;
        this.count = response.data.count;

        this.items.map((a) => {
          this.admin[a.id + a.taxYear] =
            a.assignmentAdminInfo?.id == undefined
              ? null
              : a.assignmentAdminInfo?.name +
                " " +
                a.assignmentAdminInfo?.surname;
        });
      } else {
        this.items = [];
        this.count = 0;
      }
      this.isLoading = false;
    },
    setDate(date) {
      return new Date(date + "+00:00").toLocaleString(this.$i18n.locale, {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    },
  },
  mounted() {
    const q = this.$route.query;
    if (!isNaN(q.page)) this.page = Number(q.page);
    if (!isNaN(q.status)) this.status = Number(q.status);
    if (q.search) this.search = q.search;
    this.getUsersList();
    this.adminsList(1, "", "", this.limit);
  },
};
</script>

<style lang="scss">
.account-settings {
  .lang-select-box {
    border-radius: 20px;
    border: 2px solid #01295f;
    padding: 0 1rem;
  }
}
.status-option-dropdown {
  position: absolute;
  top: 0px;
  width: 100%;
  background: white;
  z-index: 11;
  &__button {
    cursor: pointer;
  }
}

.select-box {
  border-radius: 20px !important;
  font-size: 12px;
}

.o-table__body__item {
  &:hover {
    transition: none !important;
    transform: none !important;
    color: #000;
  }
}
.lang-select-box {
  .vs__dropdown-toggle {
    border: none;
  }
}
.vs__dropdown-toggle {
  border-radius: 20px;
}
.find-admin {
  .vs__dropdown-toggle {
    padding: 4px 10px;
  }
  .vs__clear {
    display: none;
  }
}
.document-message {
  position: absolute;
  background-color: #161640;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  top: 20px;
  z-index: 9999;
  left: 0;
}
</style>
