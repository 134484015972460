<template>
  <div>
    <Customers></Customers>
  </div>
</template>

<script>
import Customers from "@/components/admin/customers";
export default {
  components: { Customers },
};
</script>

<style>
</style>